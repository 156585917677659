<template>
  <el-dialog
    v-if="value"
    :visible.sync="dialogVisible"
    top="2rem"
    width="40%"
    class="nsi-energy-object"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Энергообъекты
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit" type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
           <SaveIcon /> Сохранить
        </el-button>
        <el-button @click="handleDelete(energyObject.id)" :disabled="energyObject.isAip || energyObject.id == null" type="text" icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="energyObjectForm" :model="energyObject" label-width="18rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="energyObject.isAip"
          :disabled="true">
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="analysisIds" label="Идентификатор ПК &quot;Анализ 2009&quot;:">
        <el-select v-model="energyObject.analysisIds"
                   multiple
                   filterable
                   allow-create
                   default-first-option
                   placeholder=""
                   :disabled="!editMode">
        </el-select>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input
          v-model="energyObject.mRid"
          :disabled="!editMode || energyObject.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input
          id="energy_object-modal--name_input"
          v-model="energyObject.name"
          :disabled="!editMode || energyObject.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="regionId" label="Операционная зона ДЦ, регион:">
        <el-select v-model="energyObject.regionId" size="large" filterable clearable :disabled="!editMode || energyObject.isAip">
          <el-option
            v-for="item in operationalZones"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="ownerId" label="Организация-владелец:">
        <remote-search-input
            v-model="energyObject.ownerId"
            searchUrl="/api/organizations/SearchByName"
            id="energy_object-modal--org_name_input"
            return-prop="id"
            size="large"
            :allow-create="false"
            :show-when-init="true"
            :initial-value="energyObject.ownerOrganizationName"
            :start-length=3
            :disabled="!editMode || energyObject.isAip"
          />
      </el-form-item> 
      <el-form-item prop="psrTypeId" label="Тип:">
        <el-select v-model="energyObject.psrTypeId" size="large" filterable clearable :disabled="!editMode || energyObject.isAip">
          <el-option
            v-for="item in energyObjectPsrTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="nominalP" label="Номинальная мощность:">
        <el-input
          v-model="energyObject.nominalP"
          :disabled="!editMode || energyObject.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="baseVoltages" label="Напряжение:">
        <el-select
          multiple
          filterable
          clearable
          v-model="energyObject.baseVoltages"
          :disabled="!editMode || energyObject.isAip">
            <el-option
              v-for="item in baseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import energyObjectsApi from '@/api/nsi/energyObjects';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'EnergyObjectModal',
  props: ['value', 'energyObject'],
  mixins: [validationRules],
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    ...mapGetters('dictionaries', ['energyObjectPsrTypes', 'baseVoltages', 'operationalZones']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) this.editMode = false;
        this.$emit('input', val);
      },
    },
  },
  components: { RemoteSearchInput, SaveIcon },
  data() {
    return {
      editMode: false,
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        regionId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        analysisIds: [
          { validator: this.isUUIDList, trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
      baseVoltagesDict: [],
    };
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addEnergyObject(energyObject) {
      await energyObjectsApi.addEnergyObject(energyObject);
      this.$emit('on-create');
    },
    async updateEnergyObject(energyObject) {
      await energyObjectsApi.updateEnergyObject(energyObject);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.energyObjectForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      const request = this.energyObject.id ? this.updateEnergyObject : this.addEnergyObject;
      this.loading = true;
      await request(this.energyObject);
      this.dialogVisible = false;
      this.loading = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await energyObjectsApi.deleteEnergyObject(id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        } else {
          this.$emit('on-delete');
          this.dialogVisible = false;
        }

        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
